.highlight {
  border-color: #d9ae23; /* Highlight the card with a bright border */
}

.videoThumbnail.processing {
  filter: grayscale(100%);
  opacity: 0.7;
}

.submitButton {
  animation: 1.5s ease infinite alternate running shimmer;
  background: linear-gradient(90deg, #ffe27d 0%, #64e3ff 30%, #9192ff 85%);
  background-size: 200% 100%;
  border: none;
  border-radius: 6px;
  box-shadow:
    -2px -2px 10px rgba(255, 227, 126, 0.5),
    2px 2px 10px rgba(144, 148, 255, 0.5);
  color: #ffffff;
  cursor: pointer;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 670;
  line-height: 24px;
  overflow: hidden;
  padding: 12px 24px;
  position: relative;
  text-decoration: none;
  transition: 0.2s;
  margin-top: 18px;
}

.submitButton svg {
  left: -20px;
  opacity: 0.5;
  position: absolute;
  top: -2px;
  transition: 0.5s cubic-bezier(0.5, -0.5, 0.5, 1.5);
}

.submitButton:hover svg {
  opacity: 0.8;
  transform: translateX(50px) scale(1.5);
}

.submitButton:hover {
  transform: rotate(-3deg);
}

.submitButton:active {
  transform: scale(0.95) rotate(-3deg);
}

@keyframes shimmer {
  to {
    background-size: 100% 100%;
    box-shadow:
      -2px -2px 6px rgba(255, 227, 126, 0.5),
      2px 2px 6px rgba(144, 148, 255, 0.5);
  }
}

.urlInput {
  width: 100%;
  padding: 15px;
  font-size: 18px;
  border-radius: 8px;
  border: 2px solid #ccc;
  margin-bottom: 20px; /* Add space between input and button */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s;
}

.urlInput:focus {
  border-color: #080535;
  outline: none;
}

.urlForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 600px; /* Limit the width of the input form */
}

.inputContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100dvh;
  background: rgba(0, 0, 0, 0.5); /* Dark transparent overlay */
  z-index: 999; /* Keep the modal on top of other elements */
  display: flex;
  justify-content: center;
  align-items: center; /* Center the modal */
}

.inputTitle {
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 30px;
  color: #333;
}

.scrollContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 95px;
}

.scrolldown {
  --color: rgb(115, 115, 115);
  --sizeX: 30px;
  --sizeY: 50px;
  position: relative;
  width: var(--sizeX);
  height: var(--sizeY);
  margin-left: var(sizeX / 2);
  border: calc(var(--sizeX) / 10) solid var(--color);
  border-radius: 50px;
  box-sizing: border-box;
  margin-bottom: 16px;
}

.scrolldown::before {
  content: "";
  position: absolute;
  bottom: 30px;
  left: 50%;
  width: 6px;
  height: 6px;
  margin-left: -3px;
  background-color: var(--color);
  border-radius: 100%;
  animation: scrolldown-anim 2s infinite;
  box-sizing: border-box;
  box-shadow: 0px -5px 3px 1px #ffffff66;
}

@keyframes scrolldown-anim {
  0% {
    opacity: 0;
    height: 6px;
  }
  40% {
    opacity: 1;
    height: 10px;
  }
  80% {
    transform: translate(0, 20px);
    height: 10px;
    opacity: 0;
  }
  100% {
    height: 3px;
    opacity: 0;
  }
}

.chevrons {
  padding: 6px 0 0 0;
  margin-left: -3px;
  margin-top: 48px;
  width: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.chevrondown {
  margin-top: -6px;
  position: relative;
  border: solid var(--color);
  border-width: 0 3px 3px 0;
  display: inline-block;
  width: 10px;
  height: 10px;
  transform: rotate(45deg);
}

.chevrondown:nth-child(odd) {
  animation: pulse 500ms ease infinite alternate;
}

.chevrondown:nth-child(even) {
  animation: pulse 500ms ease infinite alternate 250ms;
}

@keyframes pulse {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.5;
  }
}

.modalContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  background-color: white;
  padding: 40px;
  border-radius: 10px;
  width: 90%;
  max-width: 750px; /* Control modal width */
  box-shadow: 0 0 15px rgba(255, 255, 255, 0.3); /* Initial shadow */
  animation: glowing 1.5s infinite alternate ease-in-out; /* Glow animation */
  z-index: 1000; /* Make sure the modal container is above the backdrop */
  margin-top: -200px; /* Move the modal slightly upwards */

  /* Animation */
  opacity: 0;
  transform: translateY(-100px); /* Start above the screen */
  animation: slideInFromTop 0.6s ease-out forwards; /* Animate downwards */
}

.animated {
  animation: slideInFromTop 0.6s ease-out forwards; /* Animate downwards */
}

@keyframes slideInFromTop {
  0% {
    opacity: 0;
    transform: translateY(-50px); /* Start slightly above */
  }
  100% {
    opacity: 1;
    transform: translateY(0); /* End at normal position */
  }
}
/* Glowing animation for the modal */

.exitButton {
  position: absolute;
  top: 16px;
  right: 16px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  transition: transform 0.1s ease;
}

.exitButton:hover {
  transform: scale(1.1);
}

.closeIcon {
  width: 24px;
  height: 24px;
  color: #666;
  transition: color 0.2s ease;
}

.tooltip {
  display: none;
  position: absolute;
  z-index: 10;
}

.underline:hover .tooltip {
  display: block;
}
