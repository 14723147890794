.inputContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 50px 0;
}

.inputTitle {
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 30px;
  color: #333;
}

.urlForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 600px;
}

.urlInput {
  width: 100%;
  padding: 15px;
  font-size: 18px;
  border-radius: 8px;
  border: 2px solid #ccc;
  margin-bottom: 20px;
  transition: border-color 0.3s;
}

.urlInput:focus {
  border-color: #080535;
  outline: none;
}

.submitButton {
  padding: 12px 24px;
  border: none;
  border-radius: 6px;
  background: linear-gradient(90deg, #ffe27d 0%, #64e3ff 30%, #9192ff 85%);
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.submitButton:hover {
  transform: scale(1.05);
}

.gridContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 30px; /* Increased gap for larger cards */
  padding: 30px;
  justify-content: center; /* Centers the grid items */
}

.studyspaceCard {
  background-color: #fff;
  border-radius: 12px; /* Slightly larger border radius */
  padding: 25px; /* More padding */
  width: 300px; /* Increased width */
  box-shadow: 0 6px 14px rgba(0, 0, 0, 0.12); /* Slightly larger shadow */
  cursor: pointer;
  transition:
    transform 0.3s ease,
    box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.studyspaceCard:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

.iconWrapper {
  background-color: #e3f2fd;
  border-radius: 50%;
  padding: 30px; /* Increased size for the icon */
  margin-bottom: 15px; /* Slightly more margin */
  display: flex;
  align-items: center;
  justify-content: center;
}

.iconWrapper i {
  font-size: 40px; /* Bigger icon */
  color: #144174;
}

.cardContent {
  text-align: center;
}

.cardContent h3 {
  margin: 0;
  font-size: 18px; /* Bigger text */
  font-weight: bold;
  color: #333;
}

.cardContent p {
  margin: 5px 0 0;
  color: #777;
  font-size: 15px; /* Slightly larger text */
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.studyspaceSection {
  padding: 20px;
  background-color: white; /* Light background to differentiate */
  flex-grow: 1;
}

.sectionTitle {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center; /* Center the title for balance */
  color: #333; /* Darker color for contrast */
}

.infoIcon {
  margin-left: 8px; /* Space between the title and the icon */
  position: relative;
  display: inline-block;
  cursor: pointer;
  color: #888; /* Light color for the icon */
}

.infoIcon i {
  font-size: 18px; /* Adjust icon size */
}

.tooltipText {
  visibility: hidden;
  width: 250px;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  font-size: 12px; /* Smaller font size */
  position: absolute;
  z-index: 1;

  /* Position tooltip to the right of the icon */
  top: 50%;
  left: 140%; /* Shift the tooltip to the right of the icon */
  transform: translateY(
    -50%
  ); /* Center the tooltip vertically relative to the icon */

  opacity: 0;
  transition: opacity 0.3s;
}

.tooltipText::after {
  content: "";
  position: absolute;
  top: 50%; /* Align the triangle with the center vertically */
  left: -10px; /* Move it just to the left of the tooltip */
  transform: translateY(-50%);
  color: #333;
  /* Create the triangle */
  border-width: 6px;
  border-style: solid;
  border-color: transparent #333 transparent transparent; /* Right-facing arrow */
}

/* Show tooltip on hover */
.infoIcon:hover .tooltipText {
  visibility: visible;
  opacity: 1;
}

/* Mobile-specific styles to position the tooltip below the icon */
@media (max-width: 980px) {
  .tooltipText {
    top: 120%; /* Position tooltip below the icon */
    left: 50%; /* Center the tooltip horizontally */
    transform: translateX(-50%); /* Adjust to center */
    max-width: 100px;
  }

  .tooltipText::after {
    top: -10px; /* Move the triangle to the top of the tooltip */
    left: 50%; /* Center the arrow horizontally */
    transform: translateX(-50%); /* Center align the triangle */
    /* Create the upside-down triangle */
    border-color: #333 transparent transparent transparent; /* Downward-facing arrow */
  }
}

.noStudyspacesMessage {
  padding: 25px;
  background-color: #f0f4f8; /* A slightly cooler, modern gray-blue background */
  border-left: 4px solid #0073e6; /* Add a tech-style blue accent on the left */
  border-radius: 6px; /* Slight rounding for a more polished look */
  color: #333; /* Dark gray text for readability */
  font-size: 18px; /* Adequate font size for readability */
  text-align: center; /* Center text alignment */
  margin-top: 40px; /* Space above the message */
  font-family: "Roboto", sans-serif; /* Clean, modern tech font */
  line-height: 1.6; /* Better readability */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05); /* Subtle shadow for depth */
}

.noStudyspacesMessage strong {
  color: #0073e6; /* Emphasis on important text with tech blue */
}

.noStudyspacesMessage p {
  margin-bottom: 0; /* Remove extra margin */
}

.noStudyspacesMessage svg {
  display: inline-block;
  vertical-align: middle;
  margin-right: 8px;
  color: #0073e6; /* Tech-style blue for the icon */
}

.noStudyspacesMessage:hover {
  background-color: #e9eff5; /* Slight background color change on hover for interactivity */
  border-left-color: #005bb5; /* Darken the blue accent on hover */
}

/* Home.module.css */

.signInLink {
  text-decoration: none;
  color: inherit; /* Keeps the text color as it is */
  transition: text-decoration 0.2s ease-in-out;
}

.signInLink:hover {
  text-decoration: underline; /* Adds underline on hover */
}
