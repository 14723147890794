.watch-container {
  display: flex;
  height: 100%;
  width: 100%;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40%; /* Takes full viewport height */
}
