/* Chatbox.module.css */
.chatArea {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid #d5d9e8;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
}

.chatComponentContainer {
  position: absolute;
  display: flex;
  flex-direction: column;
  max-height: 95%;
  width: calc(100% - 40px);
  height: 100%;
}

@media screen and (max-width: 980px) {
  .chatComponentContainer {
    width: 95%;
    margin-top: 7%;
  }
}

.chatHistory {
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 10px;
}

.messageUser {
  background-color: rgba(244, 244, 244);
  color: black;
  padding: 10px 20px;
  border-radius: 20px;
  max-width: 75%;
  margin-left: auto;
  margin-right: 0;
  margin-top: 5px;
  word-wrap: break-word;
  white-space: pre-wrap;
  opacity: 0.8;
}

.messageBot {
  background-color: transparent;
  color: #000;
  padding: 10px 20px;
  border-radius: 0;
  max-width: 100%;
  margin-left: 0;
  margin-right: 0;
  margin-top: 5px;
  opacity: 0.9;
}

.sendButton {
  width: 80px;
  background-color: #6196f3;
  color: white;
  border: none;
  border-radius: 15px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s; /* Smooth transition for background color changes */
}

.sendButton:disabled {
  background-color: #b0bec5; /* Lighter color for disabled state */
  cursor: not-allowed; /* Cursor change to indicate non-interactivity */
  color: #fff; /* Maintain white text color or change as needed */
}

@keyframes popOut {
  0% {
    transform: scale(0.9);
    opacity: 0;
  }
  100% {
    transform: scale(0.95);
    opacity: 1;
  }
}

.questionStyle {
  cursor: pointer;
  margin-bottom: 5px;
  color: black;
  font-size: 15px;
  padding: 10px 15px;
  border-radius: 8px;
  border-width: 1px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  opacity: 0; /* Start with opacity 0 */
  transform: scale(0.9); /* Start with scaled down */
  transition: background-color 0.3s ease-out; /* Add transitions for background color only */
  animation: popOut 0.3s ease-out forwards;
  animation-delay: var(--animation-delay);
  will-change: transform, opacity;
}

.questionStyle:hover {
  background-color: #fafafa;
}

.headingStyle {
  color: black;
  font-size: 15px;
  font-weight: normal;
  text-align: center;
  margin-bottom: 30px;
  margin-top: 0px;
}

.linkStyle {
  color: #1976d2;
  text-decoration: underline;
  cursor: pointer;
}

.loadingIcon {
  margin-top: 10px;
}

.questionPrompt {
  position: absolute;
  bottom: 100%; /* Aligns the bottom of the prompt right above the container */
  left: 0;
  width: 100%; /* Full width of the parent container */
  transform: translateY(-10px); /* Optional: Adjust for visual gap */
  background-color: transparent;
  border: none;
  border-radius: 5px;
  padding: 0px;
  z-index: 10;
}

.textArea {
  flex-grow: 1;
  resize: none;
  border-radius: 20px;
  padding: 9px;
  font-size: 16px;
  background-color: #f6f6f6;
  outline: none;
  border-color: #151962;
  transition: border-color 0.3s ease;
  border: 1px solid #d5d9e8;
  height: 20px;
}

.sendButton {
  width: 80px;
  height: 44px; /* Fixed height */
  background-color: #6196f3;
  color: white;
  border: none;
  border-radius: 15px;
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
  margin-left: 5px;
  transition: background-color 0.3s;
}

.questionList {
  list-style-type: none; /* Removes default bullet points */
  margin: 0;
  padding: 0;
}

.questionItem {
  padding: 8px 12px;
  margin: 5px 0;
  cursor: pointer;
  background-color: #f0f0f0;
  border-radius: 4px;
  transition:
    background-color 0.3s,
    box-shadow 0.2s;
}

.questionItem:hover,
.questionItem:focus {
  background-color: #e3f2fd; /* Light blue background on hover */
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
}

@keyframes fadeInFromTopLeft {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.newMessage {
  animation: fadeInFromTopLeft 0.5s ease-in-out;
}

.inputContainer {
  display: flex;
  flex-direction: row; /* Keep textarea and button in parallel */
  align-items: flex-end; /* Align the button to the bottom */
  justify-content: space-between; /* Ensure spacing between textarea and button */
  width: 100%;
}
