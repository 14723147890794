.aws-btn {
  --button-default-height: 44px;
  --button-default-font-size: 14px;
  --button-default-border-radius: 4px;
  --button-horizontal-padding: 16px;
  --button-raise-level: 4px;
  --button-hover-pressure: 1.25;
  --transform-speed: 0.15s;
  --button-primary-color: #1e88e5;
  --button-primary-color-dark: #1360a4;
  --button-primary-color-light: #ffffff;
  --button-primary-color-hover: #187bd1;
  --button-primary-color-active: #166dba;
  --button-primary-border: 7px solid transparent;
  --button-secondary-color: #25d366;
  --button-secondary-color-dark: #1a9247;
  --button-secondary-color-light: #ffffff;
  --button-secondary-color-hover: #25d366;
  --button-secondary-color-active: #1a9247;
  --button-secondary-border: 0px solid #1e88e5;
  --button-anchor-color: #0e4f88;
  --button-anchor-color-dark: #072743;
  --button-anchor-color-light: #ffffff;
  --button-anchor-color-hover: #0d4a7f;
  --button-anchor-color-active: #0c4271;
  --button-anchor-border: none;
  --button-danger-color: #c42e2e;
  --button-danger-color-dark: #861f1f;
  --button-danger-color-light: #ffffff;
  --button-danger-color-hover: #bc2c2c;
  --button-danger-color-active: #af2929;
  --button-danger-border: none;
}
