.popupOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popupContainer {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  text-align: center;
  max-width: 400px;
  width: 90%;
}

.popupMessage {
  font-size: 16px;
  color: #555;
  margin-bottom: 20px;
}

.popupActions {
  display: flex;
  justify-content: space-around;
}

.primaryButton {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  background-color: #007bff;
  color: white;
  transition:
    background-color 0.3s ease,
    transform 0.2s ease;
}

.primaryButton:hover {
  background-color: #0056b3;
}

.secondaryButton {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  background-color: #6c757d;
  color: white;
  transition:
    background-color 0.3s ease,
    transform 0.2s ease;
}

.secondaryButton:hover {
  background-color: #5a6268;
}
