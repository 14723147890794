/* Modal wrapper to darken the background */
.modalWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100dvh;
  background: rgba(0, 0, 0, 0.5); /* Dark transparent overlay */
  z-index: 999; /* Keep the modal on top of other elements */
  display: flex;
  justify-content: center;
  align-items: center; /* Center the modal */
}

/* Modal content with smooth slide-in animation */
.modalContent {
  position: relative;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  width: 90%;
  max-width: 750px;
  height: 80%;
  overflow-y: auto;
  z-index: 1000;
  animation: slideInFromTop 0.6s ease-out forwards; /* Animate downwards */
}

@keyframes slideInFromTop {
  0% {
    opacity: 0;
    transform: translateY(-50px); /* Start slightly above */
  }
  100% {
    opacity: 1;
    transform: translateY(0); /* End at normal position */
  }
}

@media (max-width: 768px) {
  .modalContent {
    width: calc(100% - 20px);
    height: 90%;
  }
}

.chatArea {
  flex: 1;
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 10px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  height: 100%;
  overflow-y: auto;
}

.chatComponentContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow-y: auto;
}

.chatHistory {
  flex-grow: 1;
  overflow-y: auto;

}

.messageUser {
  background-color: rgba(244, 244, 244);
  padding: 10px 20px;
  border-radius: 20px;
  max-width: 75%;
  margin-left: auto;
  margin-top: 5px;
  opacity: 0.8;
  word-wrap: break-word;
  white-space: pre-wrap;
  margin-right: 30px;
}

.messageBot {
  background-color: transparent;
  color: #000;
  padding: 20px 20px;
  border-radius: 0;
  max-width: 100%;
  margin-left: 0;
  margin-right: 0;
  margin-top: 5px;
  opacity: 0.9;
}

.textArea {
  flex-grow: 1;
  resize: none;
  border-radius: 20px;
  padding: 10px;
  font-size: 16px;
  background-color: #f6f6f6;
  outline: none;
  border-color: #4f74b2;
  transition: border-color 0.3s ease;
  border: 1px solid #d5d9e8;
  height: 20px;
}

.sendButton {
  width: 80px;
  height: 45px; /* Fixed height */
  background-color: #6196f3;
  color: white;
  border: none;
  border-radius: 15px;
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
  margin-left: 5px;
  transition: background-color 0.3s;
}

.sendButton:disabled {
  background-color: #b0bec5;
  cursor: not-allowed;
  color: #fff;
}

.exitButton {
  position: absolute;
  top: 16px;
  right: 16px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 200;
}

.closeIcon {
  width: 24px;
  height: 24px;
  color: #666;
  transition: color 0.2s ease;
  z-index: 200;
}

.inputContainer {
  display: flex;
  flex-direction: row; /* Keep textarea and button in parallel */
  align-items: flex-end; /* Align the button to the bottom */
  justify-content: space-between; /* Ensure spacing between textarea and button */
}

.emptyState {
  display: flex;
  flex-direction: column; /* Stack icon and text vertically */
  align-items: center; /* Horizontally center the content */
  justify-content: center; /* Vertically center the content */
  text-align: center; /* Ensure the text is centered */
  height: 100%; /* Full height of the container */
  row-gap: 3px;
}

@keyframes fadeInFromTopLeft {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.newMessage {
  animation: fadeInFromTopLeft 0.5s ease-in-out;
}

.linkStyle {
  color: #1976d2;
  text-decoration: underline;
  cursor: pointer;
}
