.header {
  width: 100%;
  background-color: white;
  height: 57px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-lightsoff {
  width: 100%;
  background-color: RGB(29, 29, 31);
  height: 57px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.link {
  font-size: 14px;
  font-weight: 400;
  word-spacing: 2px;
  color: black;
  transition: all 200ms;
  position: relative;
  display: inline-block;
  padding-bottom: 3px; /* Adjusts the space between the text and the underline */
}

.link:hover,
.link.active {
  text-decoration: none; /* Remove default underline */
  border-bottom: 2px solid currentColor; /* Thicker underline with the same color as the text */
  font-weight: 500;
}

.unlock-feature {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  margin-left: 1rem;
  font-size: 0.9rem; /* Adjust if necessary */
  font-weight: 500;
  color: #2a5fba;
  transition: all 200ms;
  border-width: 2px;
  border-color: #2a5fba;
  border-radius: 0.5rem;
}

.unlock-feature:hover {
  background-color: #2a5fba;
  color: white;
}

/* Styles for the inline modal under "Contact Us" */
.modalInline {
  background: white;
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 4px;
  margin-top: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: absolute;
  z-index: 999;
  width: 300px;
  left: -115px;
}

/* Mobile-specific styles for centering the modal */
@media (max-width: 980px) {
  .modalInline {
    position: fixed; /* Make sure it stays in the viewport */
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%); /* Center the modal */
    width: 90%; /* Adjust the width for smaller screens */
    max-width: 400px; /* Set a max width to avoid oversized modals */
    z-index: 50; /* Ensure it's on top */
  }
}

.modalCloseButton {
  background: none;
  border: none;
  font-size: 1.5rem;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.formGroup {
  margin-bottom: 15px;
  margin-top: 15px;
}

.formGroup label {
  display: block;
  margin-bottom: 5px;
}

.formGroup input,
.formGroup textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.submitButton {
  background-color: #192475;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.submitButton:active {
  background-color: #2146b6;
}

.contactWrapper {
  position: relative;
}

.magic-button {
  background-color: transparent;
  border: 2px solid rgb(21, 9, 64); /* Border color */
  border-radius: 10px; /* Adjusted to make it a rounded square */
  padding: 5px; /* Adjust padding for a square shape */
  cursor: pointer;
  transition:
    transform 0.3s ease,
    box-shadow 0.3s ease; /* Smooth transition */
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 42px;
  height: 34px;
}

.credits {
  color: #0284c7; /* Black text for clean, professional look */
  font-weight: 500; /* Semi-bold for clarity */
  font-family: "IBM Plex Sans", sans-serif; /* Modern, tech-friendly font */
  text-decoration: underline; /* Always-visible underline */
  text-decoration-color: #0284c7; /* Dark blue underline for subtle contrast */
  cursor: pointer; /* Pointer cursor for interactivity */
  font-size: 14px; /* Clean and readable font size */
  padding: 5px 0; /* Add padding for visual balance */
  transition: color 0.2s ease; /* Smooth hover transitions */
}

.credits:hover {
  color: #333333; /* Slightly lighter black on hover for subtle effect */
}
