@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;500;600;700&display=swap");

* {
  font-family: "IBM Plex Sans", sans-serif;
  word-spacing: -1px;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 6px;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

html {
  overscroll-behavior: none;
}
